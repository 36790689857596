/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "stdout.fm 19번째 로그에서는 테라폼 0.12 베타, 데이터독 APM, 엘라스틱 APM, 해커스 크라우드 펀딩에 대해서 이야기를 나눴습니다."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://news.chosun.com/site/data/html_dir/2017/09/28/2017092800058.html"
  }, "오빠들 1위 위해 '스밍'… 극성팬 때문에 멍든 차트 - 조선닷컴 - 연예 > K-pop")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://festa.io/events/191"
  }, "Write The Docs 서울의 2019 첫 번째 밋업 | Festa!")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.stickermule.com/"
  }, "Sticker Mule: Custom stickers that kick ass")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.msprint.co.kr/"
  }, "미성출력")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/preview-new-feature-of-terraform-012-beta-1"
  }, "테라폼 0.12 베타 1 출시 및 개선된 HCL 문법 살펴보기 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/blog/announcing-terraform-0-1-2-beta1"
  }, "Announcing Terraform 0.12 Beta 1")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://terraform-0.12.0-dev-snapshots.s3-website-us-west-2.amazonaws.com/"
  }, "테라폼 0.12 지원 프로바이더 | HashiCorp Releases")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.hashicorp.com/blog/terraform-0-1-2-preview"
  }, "HashiCorp Terraform 0.12 Preview")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/LaTeX"
  }, "LaTeX - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://en.wikipedia.org/wiki/Metafont"
  }, "Metafont - Wikipedia")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/HashiCorp/status/1013799656999006209"
  }, "HashiCorp on Twitter: \"Terraform 0.12 is coming later this summer. ...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/hashicorp/hcl2"
  }, "hashicorp/hcl2: Temporary home for experimental new version of HCL")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/mitchellh/status/1052258881848659969"
  }, "Mitchell Hashimoto on Twitter: \"Congratulations @GitHub on launching Actions! ...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/terraform-providers/terraform-provider-aws/releases/tag/v2.0.0"
  }, "Release v2.0.0 · terraform-providers/terraform-provider-aws")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.44bits.io/ko/post/what-is-the-best-editor-that-supports-terraform"
  }, "테라폼을 가장 잘 지원하는 에디터는? - 젯브레인 인텔리J를 활용한 테라폼 코드 작성 | 44bits.io")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://emacs.dev"
  }, "emacs.dev")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://vim.dev"
  }, "vim.dev")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/seaseller7/status/1097895638316986369"
  }, "달물이 on Twitter: \"한국식 MBTI를 개발했습니다. ...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/d_ijk_stra/status/1098397870640648192"
  }, "어엉부엉 on Twitter: \"트친님들의 도움을 받아 만든 개정판 개발자 MBTI… \"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.datadoghq.com/"
  }, "Modern monitoring & analytics | Datadog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://newrelic.com/"
  }, "New Relic | Real-time insights for modern software")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.datadoghq.com/apm/"
  }, "Next-generation application performance monitoring | Datadog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.elastic.co/kr/solutions/apm"
  }, "Elasticsearch을 이용한 오픈 소스 APM | Elastic")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.datadoghq.com/watchdog/"
  }, "Datadog - Watchdog")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://github.com/amatsuda/jb"
  }, "amatsuda/jb: A simple and fast JSON API template engine for Ruby on Rails")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://docs.datadoghq.com/graphing/notebooks/"
  }, "Datadog - Notebooks"), ")"), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.elastic.co/kr/products/beats/metricbeat"
  }, "Metricbeat: 경량 메트릭 수집기 | Elastic")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://twitter.com/ksoonson/status/1094778487108362240"
  }, "Soonson Kwon on Twitter: \"스티븐 레비의 해커스가 크라우드펀딩으로 복간된다는 소식. ...\"")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/goods/9410320?fbclid=IwAR2fvyI1luW-IUtM7WozWdvPbW3lIJ8apP6BuCRv1t0Q4fNjovBKrY0n3Go"
  }, "해커스 - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.facebook.com/hanbitmedia/posts/1644890952214213"
  }, "Facebook - Hanbit Media: 굿바이 해커스. 해커스가 영어책만 있는 건 아닙니다. ... ")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "http://www.yes24.com/Product/Goods/2256?scode=032&OzSrank=1"
  }, "해커 그 광기와 비밀의 기록(삼인서각) - YES24")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.aladin.co.kr/shop/UsedShop/wuseditemall.aspx?ItemId=30061214"
  }, "알라딘 중고 - 해커스 : 세상을 바꾼 컴퓨터 천재들 (무삭제판)")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
